import { styled } from "styled-components";

export const Loading = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);

  span {
    width: 40px;
    height: 40px;
    border-radius: 24px;
    border: 4px solid #3d3d3d;
    border-top-color: transparent;
    animation: spin 1.5s cubic-bezier(0.23, 1, 0.32, 1) infinite;

    @keyframes spin {
      to {
        transform: rotate(20deg);
      }

      from {
        transform: rotate(360deg);
      }
    }
  }
`;
