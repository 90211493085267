const Logo: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="140"
      height="80"
      viewBox="0 0 140 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9018 61.4419C21.1294 61.4419 18.3603 60.7998 16.5945 59.4955C14.8287 58.2113 13.6047 56.7665 12.9626 55.2014H11.4376L10.4543 60.258H0L11.4777 1.20398H22.1126L17.8386 23.2163H19.3636C20.1863 22.213 21.1896 21.2498 22.4136 20.3469C23.6377 19.4439 25.1426 18.7015 26.9887 18.1195C28.8147 17.5376 31.0018 17.2366 33.5302 17.2366C36.9012 17.2366 39.871 18.0593 42.4194 19.7248C44.9677 21.3903 46.7937 23.8183 47.9174 27.0288C49.0411 30.2394 49.1615 34.1121 48.2786 38.6671L48.0177 40.0115C47.1348 44.5664 45.5095 48.4392 43.1417 51.6497C40.7739 54.8603 38.0048 57.2882 34.8144 58.9537C31.5637 60.5991 28.2929 61.4419 24.9018 61.4419ZM23.5173 52.1514C26.788 52.1514 29.7176 51.0878 32.3061 48.9809C34.9147 46.874 36.6002 43.7839 37.3828 39.7506L37.5433 38.9078C38.3259 34.8545 37.8443 31.7844 36.0986 29.6775C34.3529 27.5706 31.8045 26.5071 28.4936 26.5071C25.2229 26.5071 22.2932 27.5706 19.7047 29.6775C17.0962 31.7844 15.4106 34.8746 14.6281 38.9078L14.4675 39.7506C13.685 43.8039 14.1866 46.874 15.9524 48.9809C17.7182 51.1079 20.2465 52.1514 23.5173 52.1514Z"
        fill={color || "#140243"}
      />
      <path
        d="M73.1807 61.4418C69.0271 61.4418 65.355 60.5589 62.2046 58.7931C59.0543 57.0273 56.6063 54.5391 54.8605 51.3286C53.1148 48.118 52.252 44.4059 52.252 40.192V39.1887C52.252 34.9147 53.1148 31.1824 54.8605 27.9719C56.6063 24.7613 59.0142 22.2731 62.1244 20.5073C65.2145 18.7415 68.7662 17.8586 72.7593 17.8586C76.632 17.8586 80.0633 18.6813 83.0531 20.3468C86.043 22.0123 88.3706 24.3801 90.0561 27.4702C91.7417 30.5604 92.5845 34.1923 92.5845 38.3459V41.3759H58.9138C59.0744 45.8105 60.5191 49.3019 63.2079 51.7901C65.9168 54.2983 69.2879 55.5424 73.3212 55.5424C76.8728 55.5424 79.5817 54.7197 81.5081 53.0944C83.4143 51.4691 84.8791 49.5227 85.9025 47.2753L91.6414 50.0644C90.7986 51.8102 89.6348 53.5559 88.1499 55.3016C86.665 57.0474 84.7186 58.5122 82.3308 59.6961C79.9429 60.8398 76.8929 61.4418 73.1807 61.4418ZM58.9941 35.8778H85.8222C85.6015 32.0452 84.2972 29.0755 81.9094 26.9284C79.5215 24.8015 76.4715 23.7179 72.7593 23.7179C68.9869 23.7179 65.8968 24.7814 63.4889 26.9284C61.0609 29.0755 59.5559 32.0452 58.9941 35.8778Z"
        fill={color || "#140243"}
      />
      <path
        d="M96.5776 60.258V53.1747C96.5776 49.0612 97.2599 45.65 98.6043 42.921C99.9487 40.192 101.915 37.9447 104.464 36.1789C107.032 34.4131 110.162 32.9683 113.874 31.8245L119.533 30.0587C121.62 29.3765 123.366 28.5538 124.77 27.5706C126.175 26.5873 127.258 25.3834 128.021 23.9788C128.783 22.5742 129.165 20.9689 129.165 19.163V18.8218C129.165 16.0126 128.202 13.7652 126.295 12.1198C124.389 10.4543 121.76 9.63164 118.449 9.63164C115.179 9.63164 112.55 10.5145 110.564 12.2803C108.557 14.0461 107.574 16.5945 107.574 19.9054V21.4304H96.7783V20.086C96.7783 15.8119 97.7415 12.18 99.6477 9.21026C101.554 6.22043 104.163 3.95298 107.453 2.36778C110.744 0.802634 114.416 0 118.47 0C122.523 0 126.175 0.782572 129.446 2.32765C132.716 3.87272 135.285 6.03984 137.171 8.82901C139.057 11.6182 140 14.8889 140 18.6613V19.5041C140 23.2765 139.218 26.467 137.673 29.0755C136.128 31.6841 134.041 33.8311 131.392 35.4966C128.743 37.1621 125.733 38.5266 122.362 39.5901L116.965 41.2756C114.537 41.998 112.671 42.8006 111.306 43.6835C109.962 44.5463 109.019 45.5095 108.477 46.5529C107.935 47.5964 107.674 48.8405 107.674 50.3053V50.6464H139.479V60.258H96.5776Z"
        fill="#B670F7"
      />
      <path
        d="M103.159 73.4012C103.159 75.4078 101.774 76.692 99.9081 76.692C99.0252 76.692 98.2828 76.391 97.7811 75.769V76.5917H96.3564V67.6624H97.8614V70.9532C98.3831 70.3712 99.1055 70.0903 99.9282 70.0903C101.794 70.1305 103.159 71.4147 103.159 73.4012ZM101.634 73.4012C101.634 72.1772 100.811 71.3946 99.7275 71.3946C98.644 71.3946 97.8213 72.1772 97.8213 73.4012C97.8213 74.6252 98.644 75.4078 99.7275 75.4078C100.831 75.4078 101.634 74.6252 101.634 73.4012Z"
        fill={color || "#140243"}
      />
      <path
        d="M109.961 70.1907L106.951 77.1335C106.369 78.5983 105.547 79.0398 104.463 79.0398C103.821 79.0398 103.159 78.819 102.737 78.4578L103.339 77.3542C103.62 77.6351 104.042 77.7957 104.443 77.7957C104.965 77.7957 105.286 77.5348 105.567 76.9128L105.667 76.6519L102.858 70.2107H104.423L106.449 74.9664L108.476 70.2107H109.961V70.1907Z"
        fill={color || "#140243"}
      />
      <path
        d="M133.319 70.5718C133.319 70.2908 133.56 70.05 133.841 70.05H138.476C138.757 70.05 138.998 70.2908 138.998 70.5718C138.998 70.8527 138.757 71.0935 138.476 71.0935H133.841C133.56 71.0935 133.319 70.8527 133.319 70.5718Z"
        fill={color || "#140243"}
      />
      <path
        d="M134.363 73.702C134.182 73.4812 134.242 73.1401 134.463 72.9796L138.155 70.1704C138.376 69.9898 138.717 70.05 138.877 70.2707C139.058 70.4914 138.998 70.8325 138.777 70.9931L135.085 73.8023C134.864 73.9628 134.543 73.9227 134.363 73.702Z"
        fill={color || "#140243"}
      />
      <path
        d="M138.917 76.3909C139.098 76.1702 139.038 75.829 138.817 75.6685L137.793 74.8859C137.573 74.7053 137.232 74.7655 137.071 74.9863C136.891 75.207 136.951 75.5481 137.171 75.7086L138.195 76.4912C138.416 76.6517 138.737 76.6116 138.917 76.3909Z"
        fill={color || "#140243"}
      />
      <path
        d="M139.058 76.0698C139.058 76.3507 138.817 76.5915 138.536 76.5915H133.901C133.801 76.5915 133.72 76.5715 133.64 76.5113C133.821 76.5915 134.021 76.5915 134.182 76.4511L135.386 75.5481H138.536C138.616 75.5481 138.677 75.5682 138.737 75.5882C138.917 75.6685 139.058 75.8491 139.058 76.0698Z"
        fill={color || "#140243"}
      />
      <path
        d="M138.074 73.2406C138.074 73.4011 137.994 73.5617 137.874 73.662L135.386 75.5482L134.182 76.4512C134.021 76.5716 133.8 76.5916 133.64 76.5114C133.58 76.4712 133.499 76.4311 133.459 76.3709C133.279 76.1502 133.339 75.8091 133.56 75.6485L137.252 72.8393C137.472 72.6587 137.814 72.7189 137.974 72.9396C138.034 73.0199 138.074 73.1403 138.074 73.2406Z"
        fill={color || "#140243"}
      />
      <path
        d="M122.443 70.0902C120.597 70.0902 119.253 71.4346 119.253 73.3208C119.253 75.207 120.597 76.5514 122.443 76.5514C124.31 76.5514 125.634 75.207 125.634 73.3208C125.634 71.4346 124.31 70.0902 122.443 70.0902ZM122.443 75.2873C121.44 75.2873 120.678 74.5248 120.678 73.3208C120.678 72.1169 121.44 71.3544 122.443 71.3544C123.467 71.3544 124.229 72.1169 124.229 73.3208C124.229 74.5248 123.467 75.2873 122.443 75.2873Z"
        fill={color || "#140243"}
      />
      <path
        d="M129.687 70.0902C127.841 70.0902 126.497 71.4346 126.497 73.3208C126.497 75.207 127.841 76.5514 129.687 76.5514C131.553 76.5514 132.878 75.207 132.878 73.3208C132.878 71.4346 131.533 70.0902 129.687 70.0902ZM129.687 75.2873C128.684 75.2873 127.921 74.5248 127.921 73.3208C127.921 72.1169 128.684 71.3544 129.687 71.3544C130.71 71.3544 131.473 72.1169 131.473 73.3208C131.473 74.5248 130.69 75.2873 129.687 75.2873Z"
        fill={color || "#140243"}
      />
      <path
        d="M115.199 70.0902C114.537 70.0902 113.915 70.2708 113.434 70.5919V73.3208C113.434 72.1169 114.196 71.3544 115.199 71.3544C116.223 71.3544 116.985 72.1169 116.985 73.3208C116.985 74.5248 116.223 75.2873 115.199 75.2873C114.196 75.2873 113.434 74.5248 113.434 73.3208V76.0498C113.935 76.3708 114.537 76.5514 115.199 76.5514C117.066 76.5514 118.39 75.207 118.39 73.3208C118.39 71.4346 117.066 70.0902 115.199 70.0902Z"
        fill={color || "#140243"}
      />
      <path
        d="M112.711 67.0403C112.33 67.0403 112.009 67.3413 112.009 67.7225V72.3979V73.3209V73.8025V75.1269V76.1502C112.009 76.5315 112.33 76.8325 112.711 76.8325C113.092 76.8325 113.413 76.5315 113.413 76.1502V67.7225C113.413 67.3413 113.112 67.0403 112.711 67.0403Z"
        fill="#140243"
      />
    </svg>
  );
};

export default Logo;
