import * as S from "./styles";

const Loading: React.FC = () => {
  return (
    <S.Loading>
      <span />
    </S.Loading>
  );
};

export default Loading;
