import { styled } from "styled-components";

export const Home = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const Login = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 400px;
  margin: auto auto;
  align-self: center;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.div`
  width: 100px;
  margin-bottom: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  padding: 16px 20px;
  background-color: #f1f1f1;
  font-family: "Poppins", sans-serif;
`;

export const LoginButton = styled.button`
  width: 100%;
  color: #286548;
  font-size: 16px;
  margin-top: 12px;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: #14f795;
`;
