import axios from "axios";
import { useEffect, useState } from "react";

import * as S from "./styles";
import Small from "../Typo/Small";
import Container from "../Container";
import P from "../Typo/P";
import H3 from "../Typo/H3";
import Loading from "../Loading";

const Rates: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rates, setRates] = useState<
    {
      id: string;
      label: string;
      visa: string;
      master: string;
      others: string;
    }[]
  >([]);
  const [antecipationRates, setAntecipationRates] = useState<
    {
      id: string;
      label: string;
      one: string;
      two: string;
      four: string;
      six: string;
      eight: string;
      ten: string;
      twelve: string;
    }[]
  >([]);

  useEffect(() => {
    const run = async () => {
      const data = (
        await axios.get(
          "https://production-be2-rates.s3.amazonaws.com/rates.json"
        )
      ).data;

      if (data.rates) setRates(data.rates);
      if (data.antecipationRates) setAntecipationRates(data.antecipationRates);
    };

    run();
  }, []);

  const onSave = async () => {
    setIsLoading(true);

    try {
      const data = {
        rates,
        antecipationRates,
      };

      const {
        data: { rates: r, antecipationRates: a },
      } = (await axios.put(
        "https://kxuilww62h.execute-api.us-east-1.amazonaws.com/production/v1/upsert-rates",
        data,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("@token")}`,
          },
        }
      )) as {
        data: {
          rates: typeof rates;
          antecipationRates: typeof antecipationRates;
        };
      };

      setRates(r);
      setAntecipationRates(a);

      alert("Taxas atualizadas com sucesso!");
    } catch (error) {
      alert(
        "Houve um erro, verifique os campos e tente novamente. Caso o erro persista, entre em contato com o desenvolvedor (47) 999 386 147 - Felipe"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth={1000}>
      <S.Rates>
        <H3 semi>Editar taxas</H3>

        {rates.length > 0 ? (
          <S.MainRates>
            <S.MainRatesRow>
              <span />

              <img alt="Visa" src="./flag-visa.png" />

              <img alt="Mastercard" src="./flag-master.png" />

              <S.Others semi>Outros</S.Others>
            </S.MainRatesRow>

            {rates.map((rate, index) => (
              <S.MainRatesRow key={rate.id}>
                <Small semi>{rate.label}</Small>

                <input
                  value={rate.visa}
                  onChange={({ target }) =>
                    setRates((curr) => {
                      const newRates = [...curr];
                      newRates[index].visa = target.value.replace(
                        /[^0-9,]/g,
                        ""
                      );
                      return newRates;
                    })
                  }
                />

                <input
                  value={rate.master}
                  onChange={({ target }) =>
                    setRates((curr) => {
                      const newRates = [...curr];
                      newRates[index].master = target.value.replace(
                        /[^0-9,]/g,
                        ""
                      );
                      return newRates;
                    })
                  }
                />

                <input
                  value={rate.others}
                  onChange={({ target }) =>
                    setRates((curr) => {
                      const newRates = [...curr];
                      newRates[index].others = target.value.replace(
                        /[^0-9,]/g,
                        ""
                      );
                      return newRates;
                    })
                  }
                />
              </S.MainRatesRow>
            ))}
          </S.MainRates>
        ) : null}

        {antecipationRates.length > 0 ? (
          <S.AntecipationRates>
            <S.AntecipationRatesHeading>
              <span />

              <div>
                <P>Taxas de antecipação</P>

                <img alt="Visa" src="./flag-visa.png" />

                <img alt="Mastercard" src="./flag-master.png" />
              </div>

              <span />
            </S.AntecipationRatesHeading>

            <S.AntecipationRatesTable>
              <S.AnteciationRatesRow>
                <span />
                <Small semi>À vista</Small>
                <Small semi>2x</Small>
                <Small semi>4x</Small>
                <Small semi>6x</Small>
                <Small semi>8x</Small>
                <Small semi>10x</Small>
                <Small semi>12x</Small>
              </S.AnteciationRatesRow>

              {antecipationRates.map((rate, index) => (
                <S.AnteciationRatesRow key={rate.id}>
                  <S.Label semi>{rate.label}</S.Label>
                  <input
                    value={rate.one}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].one = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.two}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].two = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.four}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].four = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.six}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].six = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.eight}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].eight = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.ten}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].ten = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                  <input
                    value={rate.twelve}
                    onChange={({ target }) =>
                      setAntecipationRates((curr) => {
                        const newRates = [...curr];
                        newRates[index].twelve = target.value.replace(
                          /[^0-9,]/g,
                          ""
                        );
                        return newRates;
                      })
                    }
                  />
                </S.AnteciationRatesRow>
              ))}
            </S.AntecipationRatesTable>
          </S.AntecipationRates>
        ) : null}

        <S.SaveButton onClick={onSave}>
          <P>Salvar alterações</P>
        </S.SaveButton>
      </S.Rates>

      {isLoading ? <Loading /> : null}
    </Container>
  );
};

export default Rates;
