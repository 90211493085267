import { styled } from "styled-components";

import Small from "../Typo/Small";

export const Rates = styled.section`
  gap: 40px;
  display: flex;
  padding-top: 80px;
  padding-bottom: 100px;
  flex-direction: column;

  h3 {
    color: #783ba8;
  }
`;

export const MainRates = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const Others = styled(Small)``;

export const MainRatesRow = styled.div`
  height: 64px;
  display: grid;
  padding: 0 20px;
  min-width: 480px;
  border-radius: 20px;
  align-items: center;
  background-color: #f4f4f4;
  grid-template-columns: 2fr 1fr 1fr 1fr;

  small:not(:first-of-type),
  ${Others}, img {
    margin: 0 auto;
  }

  input {
    width: 56px;
    height: 32px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    background-color: white;
    font-family: "Poppins", sans-serif;
  }

  img {
    transform: scale(0.8);
  }

  &:first-of-type {
    height: 48px;
    background-color: transparent;
  }
`;

export const AntecipationRates = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const AntecipationRatesHeading = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    width: 100%;
    height: 1px;
    background-color: #3d3d3d;
  }

  p {
    white-space: nowrap;
  }

  div {
    gap: 12px;
    display: flex;
    align-items: center;
  }

  img {
    transform: scale(0.8);
  }
`;

export const AntecipationRatesTable = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

export const Label = styled(Small)`
  margin: 0 0 !important;
`;

export const AnteciationRatesRow = styled.div`
  height: 64px;
  display: grid;
  padding: 0 20px;
  min-width: 480px;
  border-radius: 20px;
  align-items: center;
  background-color: #f4f4f4;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  small {
    margin: 0 auto;
  }

  input {
    width: 56px;
    height: 32px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    background-color: white;
    font-family: "Poppins", sans-serif;
  }

  &:first-of-type {
    height: 48px;
    background-color: transparent;
  }
`;

export const SaveButton = styled.button`
  color: #286548;
  padding: 14px 24px;
  border-radius: 40px;
  background-color: #14f795;
`;
