import { useState } from "react";

import * as S from "./styles";
import Logo from "./icons/Logo";
import Rates from "./components/Rates";
import Loading from "./components/Loading";
import axios from "axios";

const Home: React.FC = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(
    window.localStorage.getItem("@token") || ""
  );

  const isLogged = !!token;

  const onLogin = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post(
        "https://kxuilww62h.execute-api.us-east-1.amazonaws.com/production/v1/login",
        {
          user,
          password,
        }
      );

      if (data && data.token) {
        window.localStorage.setItem("@token", data.token);

        setToken(data.token);
      }
    } catch (error) {
      alert(
        "Houve um erro, verifique usuário e senha ou entre em contato com o desenvolvedor do sistema."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!isLogged) {
    return (
      <S.Home>
        <S.Login>
          <S.Logo>
            <Logo />
          </S.Logo>

          <S.Input
            value={user}
            placeholder="Usuário"
            onChange={({ target }) => setUser(target.value)}
          />

          <S.Input
            type="password"
            value={password}
            placeholder="Senha"
            onChange={({ target }) => setPassword(target.value)}
          />

          <S.LoginButton onClick={onLogin}>Entrar</S.LoginButton>
        </S.Login>

        {isLoading ? <Loading /> : null}
      </S.Home>
    );
  }

  return (
    <S.Home>
      <Rates />
    </S.Home>
  );
};

export default Home;
